import React, { useContext, useState, useEffect, Fragment } from 'react';
import { graphql } from 'gatsby';
import SourceEmitter from 'libs/emitter';
import { goToAnchor } from 'react-scrollable-anchor';
import { Row, Col } from 'react-flexbox-grid';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import ContentBlock from 'components/Content/ContentBlock';
// import HeadlineBar from 'components/Content/HeadlineBar';
import BrandCTA from 'components/BrandCta';

import ColorBlock from 'components/Content/ColorBlock';

import './recipe.scss';

const ReceipeTemplate = props => {
  const recipeData = props.data?.sanityRecipe;
  const [isLoaded, setIsLoaded] = useState(false);
  let Subscription = null;
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  const metaData = appConfigs?.metaData?.nutritionPatient;

  const titleContainsAsterisk = recipeData.title.includes('*');

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  // This function required in every page to magically calculate appropriate offset

  useEffect(() => {
    setIsLoaded(true);
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const renderNutritionFacts = () => {
    const renderfacts = recipeData?.facts.map(fact => {
      return fact?.children?.map((child, index) => {
        const CustomTag = child?.marks?.length > 0 && `${child?.marks}`;
        return child?.marks?.length > 0 ? (
          <Fragment key={index}>
            <CustomTag>{child.text}</CustomTag>
          </Fragment>
        ) : (
          <Fragment key={index}>{child.text}</Fragment>
        );
      });
    });
    return <div className="nutrition-facts">{renderfacts}</div>;
  };

  const renderServings = () => {
    const serving = recipeData?.serving.map(serving => {
      return serving?.children.map((child, index) => {
        const CustomTag = child?.marks?.length > 0 && `${child?.marks}`;
        return child?.marks?.length > 0 ? (
          <Fragment key={index}>
            <CustomTag>{child.text}</CustomTag>
          </Fragment>
        ) : (
          <Fragment>{child.text}</Fragment>
        );
      });
    });
    return <div className="servings-section main-blue-bold">{serving}</div>;
  };

  const renderIngredients = () => {
    const firstColCount = Math.ceil(
      recipeData?.ingredientContainer?.length / 2,
    );

    const initialObject = {
      firstColumn: [],
      secondColumn: [],
    };

    const ingredientColumn = () => {
      const ingredientsToRender = recipeData?.ingredientContainer.reduce(
        (acc, val, index) => {
          if (index < firstColCount) {
            return {
              firstColumn: [...acc.firstColumn, val.children[0].text],
              secondColumn: [...acc.secondColumn],
            };
          } else {
            return {
              firstColumn: [...acc.firstColumn],
              secondColumn: [...acc.secondColumn, val.children[0].text],
            };
          }
        },
        initialObject,
      );

      const renderFirstColumn = () => {
        return (
          <div className="first-column">
            {ingredientsToRender?.firstColumn?.map(col => {
              return (
                <div className="ingrident-item" key={col}>
                  {col}
                </div>
              );
            })}
          </div>
        );
      };

      const renderSecondColumn = () => {
        return (
          <div className="second-column">
            {ingredientsToRender?.secondColumn?.map(col => {
              return (
                <div className="ingrident-item" key={col}>
                  {col}
                </div>
              );
            })}
          </div>
        );
      };

      return (
        <>
          {renderFirstColumn()}
          {renderSecondColumn()}
        </>
      );
    };

    return (
      <div className="ingredient-container">
        <h2>Ingredients:</h2>
        <div className="ingredient-list-container">{ingredientColumn()}</div>
      </div>
    );
  };

  const renderPreparation = () => {
    const preparation = recipeData?.preparation?.map(prep => {
      return prep?.children?.map((child, index) => {
        const CustomTag = child?.marks?.length > 0 && `${child?.marks}`;
        return child?.marks?.length > 0 ? (
          <p key={index}>
            <CustomTag>{child.text}</CustomTag>
          </p>
        ) : (
          <p key={index}>{child.text}</p>
        );
      });
    });
    return (
      <div className="preparation-container">
        <h2>Preparation:</h2>
        {preparation}
      </div>
    );
  };

  const renderImpPI = () => {
    return (
      <Row>
        <Col xs={12}>
          <p className="strong">
            IMPORTANT PATIENT INFORMATION. Even if you have been medically
            approved to eat solid food, consult your doctor to be sure that the
            ingredients and portion size of this recipe are safe for you.
          </p>
          {titleContainsAsterisk && (
            <p className="footnote asterik">
              This recipe was provided by Froedtert & the Medical College of
              Wisconsin Weight Loss/Bariatric Surgery Program and reviewed by
              nutritionists for optimal nutritional content. Froedtert Memorial
              Lutheran Hospital/Medical College Wisconsin’s Bariatric Surgery
              Program as well as its surgeons have been designated a
              “Comprehensive Center for Bariatric Surgery” by the American
              Society of Metabolic and Bariatric Surgery. 2016 Froedtert & the
              Medical College of Wisconsin. Reprinted with permission.
            </p>
          )}
        </Col>
      </Row>
    );
  };

  const renderBtn = () => {
    const renderVideoBtn = () => (
      <BrandCTA
        href="/nutritiondirect/videos/"
        target="_blank"
        rel="noopener noreferrer"
        LinkCaption="WATCH VIDEO"
      >
        WATCH VIDEO
      </BrandCTA>
    );

    const renderAllRecipeBtn = () => (
      <BrandCTA
        href="/pdfs/NS-05402___Nutrition_Direct_Recipe_Card_Library.pdf"
        target="_blank"
        rel="noopener noreferrer"
        LinkCaption="DOWNLOAD RECIPE BOOK"
        ExternalLink
      >
        DOWNLOAD RECIPE BOOK
      </BrandCTA>
    );

    const renderSecondBtn = () =>
      recipeData.videoLink ? renderVideoBtn() : renderAllRecipeBtn();

    return (
      <div className="download-recipe">
        <Row>
          <Col xs={12} md={6}>
            <BrandCTA
              href={`${recipeData?.pdf?.asset.url}?dl=${recipeData?.pdf?.asset.originalFilename}`}
              target="_blank"
              rel="noopener noreferrer"
              LinkCaption="DOWNLOAD FULL RECIPE"
            >
              DOWNLOAD FULL RECIPE
            </BrandCTA>
          </Col>
          <Col xs={12} md={6}>
            {renderSecondBtn()}
          </Col>
        </Row>
      </div>
    );
  };

  const renderRecipeContent = () => {
    return (
      <>
        <ColorBlock white>
          <Row>
            <Col xs={12} md={4}>
              <img
                src={recipeData?.thumbnail?.asset?.url}
                alt={recipeData?.title}
                className="recipe-img"
              />
            </Col>
            <Col xs={12} md={8}>
              <Row>
                <Col xs={12}>
                  <h3 className="main-blue-bold">{recipeData?.title}</h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>{renderNutritionFacts()}</Col>
              </Row>
              <Row>
                <Col xs={12}>{renderServings()}</Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>{renderIngredients()}</Col>
          </Row>
          <Row>
            <Col xs={12}>{renderPreparation()}</Col>
          </Row>
          {renderImpPI()}
        </ColorBlock>
        {renderBtn()}
      </>
    );
  };

  const renderPage = () => {
    return (
      isLoaded && (
        <div id="nutrition-patient-recipe-detail">
          <ContentBlock>
            {/* HEAT THINGS UP WITH HEALTHY AND DELICIOUS RECIPES */}
            <div className="body-content-section">{renderRecipeContent()}</div>
          </ContentBlock>
        </div>
      )
    );
  };

  return (
    <Layout indication={indication} className="nutrition-patient-recipe-detail">
      <Seo {...metaData} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default ReceipeTemplate;

export const PageQuery = graphql`
  query sanityRecipeQuery($slug: String!) {
    sanityRecipe(slug: { current: { eq: $slug } }) {
      title
      ingredientContainer {
        children {
          marks
          text
        }
      }
      facts {
        children {
          text
          marks
        }
      }
      serving {
        children {
          marks
          text
        }
      }
      mealType {
        name
      }
      preparation {
        children {
          marks
          text
        }
      }
      thumbnail {
        asset {
          url
        }
      }
      pdf {
        asset {
          url
          originalFilename
        }
      }
      video
      videoLink
    }
  }
`;
